import React, { lazy, Suspense } from "react";
import { Route, Routes,useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { CartProvider } from "../ExcessCode/CartContext";
import { ProductProvider } from '../ExcessCode/ProductContext';
import { FooterWithSitemap } from "../Components/Footer";
import { Navibar } from "../Components/Navbar";
import { ProductIdProvider } from "../ExcessCode/ProductIdContext";



// import Invoice2 from "../Pages/SellerControlCenter/Invoice2";

const Homepage = lazy(() => import("../Homepage"));
const Login = lazy(() => import("../Pages/Login"));
const SimpleRegistrationForm = lazy(() => import("../Pages/Register"));
const MainSparesCatalogue = lazy(() => import("../Pages/MainSpareCatalogue"));
const ProductPage = lazy(() => import("../Pages/ProductPage"));
const ProductCheckout = lazy(() => import("../Pages/Checkoutpage"));
const NavMenubar = lazy(() => import("../Components/NavMenubar"));
const UsedGaadi = lazy(() => import("../Pages/AutomartPlus/UsedGaadi/UsedGaadi"));
const PrivateRoute = lazy(() => import("../Pages/Private"));
const AdminDash = lazy(() => import("../Pages/Adminpages/AdminDash"));
const ManageProducts = lazy(() => import("../Pages/Adminpages/ManageProducts"));
const AddProduct = lazy(() => import("../Pages/Adminpages/AddProduct"));
const EditProductForm = lazy(() => import("../Pages/Adminpages/UpdateProduct"));
const AdminLogout = lazy(() => import("../Pages/Adminpages/AdminLogout"));
const HathGaadi = lazy(() => import("../Pages/ShoppingCart"));
const SupportPage = lazy(() => import("../Pages/helpandsupport/SupportPage"));
const Helppage = lazy(() => import("../Pages/helpandsupport/Help"));
const PrivacyPage = lazy(() => import("../Pages/helpandsupport/PrivacyPage"));
const ShippingPage = lazy(() => import("../Pages/helpandsupport/Shippingpolicy"));
const OrderSuccess = lazy(() => import("../Pages/OrderSuccess"));
const MyOrdersPage = lazy(() => import("../Pages/OrderSumary"));
const CardGrid = lazy(() => import("../Pages/AutomartPlus/UsedGaadi/CardGrid"));
const CarDetails = lazy(() => import("../Pages/AutomartPlus/UsedGaadi/CarDetails"));
const ServiceMainPage = lazy(() => import("../Pages/AutomartPlus/AutoServices/ServiceMainPage"));
const CarForm = lazy(() => import("../Pages/AutomartPlus/UsedGaadi/FormC"));
const GarageForm = lazy(() => import("../Pages/AutomartPlus/AutoServices/GarageForm"));
const SellerForm = lazy(() => import("../Pages/AutomartPlus/AutoServices/SellerForm"));
const EngineerForm = lazy(() => import("../Pages/AutomartPlus/AutoServices/EngineerForm"));
const AutomartBusiness = lazy(() => import("../Pages/AutomartPlus/Business/AutomartBusiness"));
const RefundPolicy = lazy(() => import("../Pages/helpandsupport/refundpolicy"));
const Profile = lazy(() => import("../Pages/Profile"));
const Usercars = lazy(() => import("../Pages/AutomartPlus/UsedGaadi/Usercars"));
const Slider = lazy(() => import("../Pages/Adminpages/Slider"));
const ContactForm = lazy(() => import("../Pages/helpandsupport/Contactus"));
const BulkEnquiryForm = lazy(() => import("../Pages/helpandsupport/BulkEnquiry"));
const OrderFailure = lazy(() => import("../Pages/OrderFailure"));
const SubCategoryPage = lazy(() => import("../Components/Categoriesbytype"));
const SortedProductsGridPage = lazy(() => import("../Pages/sortedproducts"));
const Notfoundpage = lazy(() => import("../Components/404"));
const Major = lazy(() => import("../Components/search/major"));
const Feedbackform = lazy(() => import("../Pages/helpandsupport/feedback"));
const GaragesTable = lazy(() => import("../Pages/Adminpages/GaragesData"));
const UsedTable = lazy(() => import("../Pages/Adminpages/useddata"));
const TechnicianTable = lazy(() => import("../Pages/Adminpages/TechnicianData"));
const AdminOrdersControlPanel = lazy(() => import("../Pages/Adminpages/adminorderspanel"));
const Invoice = lazy(() => import("../Pages/Adminpages/Invoice"));
const Shippingrate = lazy(() => import("../Pages/Adminpages/Shippingrate"));
const ContactTable = lazy(() => import("../Pages/Adminpages/ContactData"));
const FeedbackTable = lazy(() => import("../Pages/Adminpages/Feebackmanager"));
const BulkEnquiryTable = lazy(() => import("../Pages/Adminpages/BulkEnquiry"));
const Searchdata = lazy(() => import("../Pages/Adminpages/Searchdata"));
const Userdata = lazy(() => import("../Pages/Adminpages/Userdata"));
const Neworders = lazy(() => import("../Pages/Adminpages/NewOrders"));
const DispatchOrders = lazy(() => import("../Pages/Adminpages/Dispatchorders"));
const Cancelorders = lazy(() => import("../Pages/Adminpages/Cancelorders"));
const Replaceorders = lazy(() => import("../Pages/Adminpages/Replaceorder"));
const ReturnOrders = lazy(() => import("../Pages/Adminpages/Returnorders"));
const Seller = lazy(() => import("../Pages/Adminpages/Seller"));
const ManageNewProduct = lazy(() => import("../Pages/Adminpages/Newproduct"));
const UpdatedProducts = lazy(() => import("../Pages/Adminpages/Updatedproducts"));
const Coupon = lazy(() => import("../Pages/Adminpages/AddCoupon"));
const SellerBenefits = lazy(() => import("../Pages/SellerBenefits"));
const ViewProductForm = lazy(() => import("../Pages/Adminpages/Productview"));
const Comingsoon =  lazy(() => import("../Components/Comingsoon"));
const SellerProfileView = lazy(() => import("../Pages/Adminpages/Sellerview"));
const Listings = lazy(() => import("../Pages/Adminpages/Listings"));
const ARemittance = lazy(() => import("../Pages/Adminpages/ARemittance"));
// Seller imports
const SellerDash = lazy(() => import("../Pages/SellerControlCenter/SellerDash"));
const SManageProducts = lazy(() => import("../Pages/SellerControlCenter/SManageProducts"));
const SAddProduct = lazy(() => import("../Pages/SellerControlCenter/SAddProduct"));
const SEditProductForm = lazy(() => import("../Pages/SellerControlCenter/SUpdateProduct"));
const SellerLogout = lazy(() => import("../Pages/SellerControlCenter/SellerLogout"));
const SAllorders = lazy(() => import("../Pages/SellerControlCenter/Allorders"));
const Invoice2 = lazy(() => import("../Pages/SellerControlCenter/Invoice2"));
const SNeworders = lazy(() => import("../Pages/SellerControlCenter/Neworders"));
const SDispatchorders = lazy(() => import("../Pages/SellerControlCenter/Dispatchorders"));
const SCancelorders = lazy(() => import("../Pages/SellerControlCenter/SCancelorders"));
const SReplaceorders = lazy(() => import("../Pages/SellerControlCenter/Replaceorders"));
const SReturnorders = lazy(() => import("../Pages/SellerControlCenter/Returnorders"));
const Sprofile = lazy(() => import("../Pages/SellerControlCenter/Profile"));
const ChangePassword = lazy(() => import("../Components/ChangePassword"));
const SitemapGenerator = lazy(() => import("../SitemapGenerator"));
const Remittance = lazy(() => import("../Pages/SellerControlCenter/Remittance"));
//Marketing 

const MarketingDash = lazy(() => import("../Pages/Marketingpage/MarketingDash"));
const MarketingLogout= lazy(() => import("../Pages/Marketingpage/MarketingLogout"));
const MUpdatedProducts = lazy(() => import("../Pages/Marketingpage/MUpdatedproducts"));
const MNewProduct = lazy(() => import("../Pages/Marketingpage/MNewproduct"))

function AnimatedRoutes() {
  const location = useLocation();
  const isUserAdminRoute = location.pathname.startsWith("/admin");
  const isSellerRoute = location.pathname.startsWith("/seller");
  const isSitemap =location.pathname.startsWith("/sitemap");
  const ismarketing =location.pathname.startsWith("/marketing");
  return (
    <AnimatePresence>
         <CartProvider>
    
      {!isUserAdminRoute && !isSellerRoute && !isSitemap && !ismarketing && <Navibar />}
     <ProductIdProvider>
      <ProductProvider>

      <Routes location={location}>
      <Route path="/sitemap" element={ <SitemapGenerator /> } />
        <Route path="/" element={ <Homepage /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/register" element={ <SimpleRegistrationForm /> } />
        <Route path="/shop" element={ <MainSparesCatalogue /> } />
        <Route path="/forgotpassword/:email" element={<ChangePassword />} />
        <Route path="/sellerbenefits" element={ <SellerBenefits /> } />

        <Route path="/productdetails" element={ <ProductPage /> } />
        <Route path="/navmenu" element={ <NavMenubar /> } />
        <Route path="/shop/:mainCategory/:subCategory/:productName/:productId" element={ <ProductPage /> } />
        <Route path="/cart" element={<HathGaadi />} />
        <Route path="/ordersuccess" element={<OrderSuccess />} />
        <Route path="/orderfailure/failed" element={<OrderFailure />} />
        <Route path="/comingsoon"element={<Comingsoon />} />
        
        {/* <Route path="/myprofile/usercars" element={<Usercars />} /> */}


        <Route path="/reuse" element={<UsedGaadi />} />
        <Route path="/reuse/vehicles" element={<CardGrid />} />

        <Route path="/termsandcondition" element={<SupportPage />} />
        <Route path="/helpcenter" element={<Helppage />} />
        <Route path="/privacypolicy" element={<PrivacyPage />} />
        <Route path="/shippingpolicy" element={<ShippingPage />} />
        <Route path="/returnpolicy" element={<RefundPolicy />} />
       
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/feedback" element={<Feedbackform />} />
        <Route path="/bulkenquiry" element={<BulkEnquiryForm />} />
        <Route path="/reuse/:preName/:precity/:preownedid" element={<CarDetails />} />
        <Route path="/services" element={ <AutomartBusiness />  } />
        <Route path="/business" element={<ServiceMainPage /> } />
        <Route
  path="/categories/:category" // Define a dynamic segment for the category
  element={<SubCategoryPage />}
/>
<Route path="/categories/:main/:selectedCategory" element={<SortedProductsGridPage />} />
<Route path="/404-error" element={<Notfoundpage />} />
<Route path="/major" element={<Major />} />
<Route path="/seller-details" element={ <SellerForm /> } />


        <Route path="/seller" element={ <PrivateRoute type="seller"/> } >
       <Route path="/seller/dashboard" element={ <SellerDash /> } />
          <Route path="/seller/smanageproducts" element={ <SManageProducts /> } />
          <Route path="/seller/smanageproducts/saddproduct" element={ <SAddProduct />} />
          <Route path="/seller/slogout" element={ <SellerLogout />} />
          <Route path="/seller/smanageproduct/sedit/:id" element={ <SEditProductForm />} />
          <Route path="/seller/allorders" element={ <SAllorders /> } />
          <Route path="/seller/allorders/invoice2" element={ <Invoice2 /> } />
          <Route path="/seller/neworders" element={ <SNeworders /> } />
          <Route path="/seller/dispatchorders" element={ <SDispatchorders /> } />
          <Route path="/seller/cancelorders" element={ <SCancelorders /> } />
          <Route path="/seller/replaceorders" element={ <SReplaceorders/> } />
          <Route path="/seller/returnorder" element={ <SReturnorders/> } />
          <Route path="/seller/profile" element={ <Sprofile /> } />
          <Route path="/seller/remittance" element={ <Remittance /> } />
        </Route>
         <Route path="/marketing" element={ <PrivateRoute type="marketing"/> } >
        <Route path="/marketing/dashboard" element={ <MarketingDash /> } />
       <Route path="/marketing/addnewproducts" element={ <MNewProduct/> } />
       <Route path="/marketing/updatedproducts" element={<MUpdatedProducts />} />
       <Route path="/marketing/logout" element={ <MarketingLogout /> } />
</Route> 
        <Route path="/admin" element={ <PrivateRoute type="admin"/> }>
          <Route path="/admin/dashboard" element={ <AdminDash /> } />
          <Route path="/admin/manageproducts" element={ <ManageProducts /> } />
          <Route path="/admin/manageproducts/addproduct" element={ <AddProduct />} />
          <Route path="/admin/logout" element={ <AdminLogout />} />
          <Route path="/admin/manageproduct/edit/:id" element={ <EditProductForm />} />
          <Route path="/admin/manageseller" element={ <Seller /> } />
          <Route path="/admin/manageslider" element={ <Slider /> } />
          <Route path="/admin/GarageDetails" element={ <GaragesTable /> } />
          <Route path="/admin/technician" element={ <TechnicianTable /> } />
          <Route path="/admin/usedcar" element={ <UsedTable /> } />
          <Route path="/admin/contactenquiry" element={ <ContactTable /> } />
          <Route path="/admin/feedbackdetails" element={ <FeedbackTable /> } />
          <Route path="/admin/searchdata" element={ <Searchdata /> } />
          <Route path="/admin/userdata" element={ <Userdata /> } />
          <Route path="/admin/bulkenquiry" element={ <BulkEnquiryTable /> } />
          <Route path="/admin/orderscontrol" element={ <AdminOrdersControlPanel /> } />
          <Route path="/admin/orderscontrol/invoice" element={<Invoice />} />
          <Route path="/admin/neworders" element={ <Neworders /> } />
          <Route path="/admin/dispatchorders" element={ <DispatchOrders /> } />
          <Route path="/admin/cancelorders" element={ <Cancelorders /> } />
          <Route path="/admin/returnorders" element={ <ReturnOrders /> } />
          <Route path="/admin/replaceorders" element={ <Replaceorders /> } />
          <Route path="/admin/shippingrate" element={ <Shippingrate/> } />
          <Route path="/admin/addnewproducts" element={ <ManageNewProduct /> } />
          <Route path="/admin/addnewproducts/view/:id" element={ <ViewProductForm /> } />
          <Route path="/admin/seller/view/:name" element={ <SellerProfileView /> } />
          <Route path="/admin/seller/view/listings/:id" element={ <Listings/> } />
          <Route path="/admin/seller/view/remittance/:id" element={ <ARemittance/> } />
          <Route path="/admin/updatedproducts" element={ <UpdatedProducts /> } />
          <Route path="/admin/addcoupon" element={ <Coupon /> } />


        </Route>
        <Route path="/checkout" element={<ProductCheckout />} />
           {/* User Routes */}
           <Route path="/user" element={<PrivateRoute type="user" />}>
           {/* <Route path="/user/productcheckout" element={<ProductCheckout />} /> */}
           <Route path="/user/myorders" element={<MyOrdersPage />} />
           <Route path="/user/vehicle-details-form" element={ <CarForm /> } />
           <Route path="/user/myprofile" element={<Profile />} />
           <Route path="/user/vehicledetails/myvehicle" element={ <Usercars /> } />
           <Route path="/user/technician-details" element={ <EngineerForm /> } />
           <Route path="/user/garage-details" element={ <GarageForm /> } />

        </Route>
      </Routes> 
      </ProductProvider>
      </ProductIdProvider>

      {!isUserAdminRoute && !isSellerRoute  && !isSitemap && !ismarketing && <FooterWithSitemap/>}
      </CartProvider>

    </AnimatePresence>
  );
}

export default AnimatedRoutes;